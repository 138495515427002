import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';

import { analyticsEvent } from '@shared/services/analytics';

import Button from '../common/Button/Button.tsx';
import EmblaCarousel from '../common/Carousel/CarouselWrapper';
import { useDotButton } from '../common/Carousel/EmblaCarouselDotButton.tsx';
import Tip from '../common/Tip';
import { usePrevNextButtons } from './consts.ts';

interface TipsAboutModalProps {
    isOpen: boolean;
    primaryText?: string;
    secondaryText?: string;
    onCancel?: () => void;
    options?: EmblaOptionsType;
}

const TIPS = [
    '+00 111 222 333',
    'inst: @nik_name',
    'https://site/page',
    '&#$@^@%89!!!🤬',
];
const SLIDES_MESSAGES = [
    'Keep your contact info private',
    'Avoid sharing social handlers',
    'No links, please',
    'Keep it respectful',
];

const ANALYTICS_TIPS = [
    'phone_number',
    'social_handles',
    'no_links',
    'keep_respectful',
];

const TipsAboutModal: React.FC<TipsAboutModalProps> = ({
    isOpen,
    primaryText = 'Continue',
    secondaryText = 'Close',
    onCancel,
    options,
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options);
    const { onNextButtonClick } = usePrevNextButtons(emblaApi);
    const { selectedIndex, onDotButtonClick } = useDotButton(emblaApi);

    const handleOverlayClick = () => {
        if (onCancel) {
            onCancel();
            analyticsEvent('profile_safety_tips_close_pressed');
        }
    };

    if (!isOpen) return null;

    return (
        <div className='fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center text-black'>
            <div
                className='fixed top-0 left-0 h-full w-full bg-gray-500 bg-opacity-50'
                onClick={handleOverlayClick}
            />
            <div className='fixed max-w-[300px] content-center rounded-lg bg-white p-2 pt-6 pb-2 text-center shadow-lg md:max-w-[450px]'>
                <EmblaCarousel
                    forwardedRef={emblaRef}
                    slides={SLIDES_MESSAGES.map((message) => (
                        <Slide
                            key={TIPS[selectedIndex]}
                            tipText={TIPS[selectedIndex]}
                            message={message}
                        />
                    ))}
                    emblaApi={emblaApi}
                />
                <div className='mx-auto my-6 flex max-w-[230px] flex-col justify-center gap-2 sm:max-w-[300px]'>
                    {selectedIndex !== 3 ? (
                        <Button
                            className='py-2'
                            variant='primary'
                            label={primaryText}
                            onClick={() => {
                                analyticsEvent(
                                    `safety_tip_${ANALYTICS_TIPS[selectedIndex]}_next_pressed`
                                );
                                onNextButtonClick();
                            }}
                        />
                    ) : (
                        <Button
                            className='py-2'
                            variant='primary'
                            label='Done'
                            onClick={() => {
                                analyticsEvent(
                                    'profile_safety_tips_done_pressed'
                                );
                                onCancel?.();
                            }}
                        />
                    )}
                    {selectedIndex !== 3 ? (
                        <Button
                            label={secondaryText}
                            onClick={handleOverlayClick}
                        />
                    ) : (
                        <Button
                            label='See these tips again'
                            onClick={() => {
                                analyticsEvent(
                                    'profile_safety_tips_see_again_pressed'
                                );
                                onDotButtonClick(0);
                            }}
                        />
                    )}
                </div>
                <div className='mx-4 rounded-md px-4 py-2 text-gray-400'>
                    Learn more about our
                    <a
                        href='/community-guidelines'
                        target='_blank'
                        className='text-system-blue'
                        rel='noreferrer'
                    >
                        {' '}
                        Community guidelines
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TipsAboutModal;

interface SlideProps {
    tipText: string;
    message: string;
}

const Slide: React.FC<SlideProps> = ({ tipText, message }) => (
    <div className='my-4 flex flex-col items-center gap-5'>
        <Tip aling='right' isSuccess={false}>
            <div>
                <span className='rounded-lg border border-gray-500 px-4 py-2 font-semibold text-black'>
                    {tipText}
                </span>
            </div>
        </Tip>
        <span>{message}</span>
    </div>
);
