import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { sendReactionDiscovery } from '@/api/user';
import RedHeart from '@/assets/icons/heart-red.svg?react';
import HelloReaction from '@/assets/icons/hello-reaction.svg?react';
import LikeReaction from '@/assets/icons/like-reaction.svg?react';
import PassReaction from '@/assets/icons/pass-reaction.svg?react';
import { UserDetailed, unpackUserInterests } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import matchesStore from '@/entities/match/store';
import { LikedOrViewedUsers } from '@/entities/user';
import userStore from '@/entities/user/store';
import useScrollEffect from '@/hooks/useScrollEffect';
import { useSwipeAndClose } from '@/hooks/useSwipeAndClose';
import { useSwipeAndReactions } from '@/hooks/useSwipeAndReactions';
import { startStaleUserCleanup } from '@/utils/deleteViewedUsers';
import {
    getRotateValue,
    getTranslateXValue,
    getTranslateYValue,
} from '@/utils/domTransformUtils';
import { interestsNames } from '@/utils/interestsNames';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import { analyticsEvent } from '@shared/services/analytics';

import CloseButton from '../auth/CloseButton';
import Button from '../common/Button/Button';
import MultiSelectItem from '../common/MultiSelecetItem';
import DiscoveryActions from '../discovery/DiscoveryActions';
import { TWELWE_HOURS } from '../likedYou/LikedUserCard';
import ProfilePreview from '../profile/ProfilePreview';
import ProfilePreviewActions from '../profile/ProfilePreviewActions';
import ProfilePreviewHeader from '../profile/ProfilePreviewHeader';
import Modal from './Modal';
import ProfilePreviewModal from './ProfilePreviewModal';

interface ModalRendererProps {
    isOpenModal: any;
    setIsOpenModal: (arg: any) => void;
    user: any;
    visibleUser: UserDetailed;
    isLiked: boolean;
    setLiked: React.Dispatch<React.SetStateAction<LikedOrViewedUsers>>;
    setViewed: React.Dispatch<React.SetStateAction<LikedOrViewedUsers>>;
    type: string;
    setBumpUser: (arg: any) => void;
    setIsOpenBumpModal: (arg: boolean) => void;
    fromNotification?: boolean;
}

const LikedYouModals: React.FC<ModalRendererProps> = ({
    isOpenModal,
    setIsOpenModal,
    user,
    visibleUser,
    isLiked,
    setLiked,
    setViewed,
    type,
    setBumpUser,
    setIsOpenBumpModal,
    fromNotification = false,
}) => {
    const navigate = useNavigate();
    const mainDivRef = useRef<HTMLDivElement | null>(null);
    const childDivRef = useRef<HTMLDivElement | null>(null);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isOpenModalDots, setIsOpenModalDots] = useState<boolean>(false);
    const [iconPositions, setIconPositions] = useState<number>(0);
    const [showLikeIcon, setShowLikeIcon] = useState<boolean>(false);
    const [modalWidth, setModalWidth] = useState<number>(
        window.innerWidth * 0.9 > 500 ? 500 : window.innerWidth * 0.9
    );
    const [showButtons, setShowButtons] = useState<boolean>(false);
    const handleSelectChat = () => {
        navigate(`${AppRoutes.Chat}/${visibleUser?.id}`);
    };
    const handleCloseModal = () => {
        matchesStore.setNewMatch({ match: null, user: null });
        setIsOpenModal({ list: undefined, user: undefined, type: undefined });
        localStorage.removeItem(LOCAL_STORAGE.UserNickname);
        setIsOpenModalDots(false);
        if (showLikeIcon) {
            setTimeout(() => {
                setShowLikeIcon(false);
            }, 1000);
        }
        localStorage.removeItem(LOCAL_STORAGE.BlockRefresh);
    };

    const rotateValue = getRotateValue(mainDivRef.current) || 0;
    const translateXValue = getTranslateXValue(mainDivRef.current) || 0;
    const translateYValue = getTranslateYValue(mainDivRef.current) || 0;
    const { actionsSize, opacityVisible, setScrollPosition, scrollY } =
        useScrollEffect(childDivRef, isOpenModal);

    const { translateY } = useSwipeAndClose(
        childDivRef,
        isOpenModal,
        handleCloseModal
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleSendReaction = useCallback(
        async (type: string) => {
            if (type === 'super_smash') {
                setShowLikeIcon(true);
            }
            try {
                const res = await sendReactionDiscovery(
                    localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                    type,
                    visibleUser.nickname || '',
                    isLiked ? 'answer' : 'visit'
                );
                if (type === 'smash' || type === 'super_smash') {
                    if (res.result.match) {
                        matchesStore.setMatches({
                            ...matchesStore.matches,
                            list_new: [
                                res.result.match,
                                ...matchesStore.matches.list_new,
                            ],
                            users: [
                                res.result.user,
                                ...matchesStore.matches.users,
                            ],
                            unreads: {
                                ...matchesStore.matches.unreads,
                                matches:
                                    matchesStore.matches.unreads.matches + 1,
                            },
                        });
                        userStore.setTotalReactions(
                            userStore.home?.reactions.total_count - 1
                        );
                        setBumpUser(res.result);
                        setIsOpenBumpModal(true);
                        discoveryStore.addViewedUser({
                            id: visibleUser?.id,
                            time: new Date().getTime(),
                        });
                        startStaleUserCleanup();
                    }
                }
            } catch (error: any) {
                console.error(error.response?.data?.m);
                if (error.response?.data?.m === 'e_balance_smashes_low') {
                    userStore.setMeData({
                        ...userStore.home.me,
                        balance: {
                            ...userStore.home.me.balance,
                            smashes: 0,
                            free_smashes_bonus_next_at:
                                Date.now() + TWELWE_HOURS,
                        },
                    });
                }
            } finally {
                if (isLiked) {
                    setLiked((prevUsers: any) => ({
                        total_count: prevUsers.total_count - 1,
                        users: prevUsers.users?.filter(
                            (us: any) => us?.id !== user.user_id
                        ),
                        list: prevUsers.list.filter(
                            (us: any) => us.user_id !== user.user_id
                        ),
                    }));
                } else {
                    setViewed((prevUsers: any) => ({
                        total_count: prevUsers.total_count - 1,
                        visitors: prevUsers.visitors.filter(
                            (us: any) => us.id !== user.user_id
                        ),
                        list: prevUsers.list.filter(
                            (us: any) => us.user_id !== user.user_id
                        ),
                    }));
                }

                handleCloseModal();
            }
        },
        [visibleUser]
    );

    const { handleActions } = useSwipeAndReactions(
        setIconPositions,
        visibleUser,
        isSending,
        setIsSending,
        setIsOpenModalDots,
        handleSendReaction,
        type === 'basic' && !translateY,
        mainDivRef,
        setShowLikeIcon,
        childDivRef
    );

    const buttons = useMemo(
        () => [
            {
                text: 'Block',
                textColor: '',
                onClick: () => {
                    analyticsEvent('Card_OptionBlock_Press');
                    handleSendReaction('pass');
                },
            },
            {
                text: 'Block and Report',
                textColor: 'error',
                onClick: () => {
                    analyticsEvent('Card_OptionBlockAndUnmatch_Press');
                    matchesStore.setReportUser(visibleUser);
                    if (!isLiked) {
                        localStorage.setItem(LOCAL_STORAGE.Tab, 'viewed');
                    }
                    navigate(
                        `${AppRoutes.Report}/${visibleUser?.id}/${isLiked ? 'liked' : 'visit'}`
                    );
                },
            },
        ],
        [visibleUser, isLiked, navigate, handleSendReaction]
    );
    const interests: number[] = unpackUserInterests(
        userStore.home?.me?.user || {}
    );

    const userInterests = unpackUserInterests(user?.user || visibleUser || {});
    const filteredInterests = userInterests.filter((interest: number) =>
        interests.includes(interest)
    );
    useEffect(() => {
        if (!isOpenModalDots) {
            setTimeout(() => {
                setShowButtons(isOpenModalDots);
            }, 500);
        } else setShowButtons(isOpenModalDots);
    }, [isOpenModalDots]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        childDivRef?.current?.clientWidth &&
            setModalWidth(childDivRef?.current?.clientWidth);
    }, [isOpenModal, childDivRef?.current?.clientWidth]);
    const blockAndReportLocation =
        (childDivRef.current?.scrollHeight ?? 0) -
        (childDivRef.current?.scrollTop ?? 0) -
        (childDivRef.current?.clientHeight ?? 0);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const renderProfilePreview = useCallback(
        () => (
            <Modal
                isOpen={isOpenModal}
                onClose={handleCloseModal}
                setScrollPosition={setScrollPosition}
                mainDivRef={mainDivRef}
                childDivRef={childDivRef}
                isOpenModalDots={isOpenModalDots}
                setIsOpenModalDots={setIsOpenModalDots}
                style={
                    translateY
                        ? {
                              transform: `translateY(${translateY}px)`,
                          }
                        : {}
                }
                buttons={buttons}
                className={twMerge(
                    'p-0',
                    Math.abs(iconPositions) > 1 || translateY
                        ? 'overflow-hidden'
                        : 'overflow-y-auto'
                )}
                zIndex={fromNotification ? 50 : 40}
                fromLikedYou
            >
                <div
                    className={twMerge(
                        'max-h-full select-none overflow-hidden overscroll-none p-0 pb-10',
                        type === 'basic' && 'pb-0'
                    )}
                >
                    <ProfilePreview
                        userData={visibleUser}
                        modal
                        type={type}
                        actionSize={actionsSize}
                        opacityVisible={opacityVisible}
                        setIsOpenModalDots={setIsOpenModalDots}
                        headRef={childDivRef}
                        scrollY={scrollY}
                        actionOnClick={handleSelectChat}
                        fromLikedYou
                        source='visit'
                    />
                    <div className='-translate-x-1/2 fixed top-1/2 left-1/2 flex h-16 w-16 items-center justify-center rounded-full '>
                        {showLikeIcon && (
                            <LikeReaction className='animate-scaleMinToMax' />
                        )}
                    </div>
                    <div
                        className={twMerge(
                            'fixed top-1/2 flex h-16 w-16 items-center justify-center rounded-full ',
                            iconPositions < 0 ? '' : 'right-0 ',
                            Math.abs(iconPositions) > 5
                                ? 'opacity-100'
                                : 'opacity-0'
                        )}
                        style={{
                            transform: `translateX(${
                                iconPositions < 0
                                    ? iconPositions * -3
                                    : iconPositions * -3
                            }px)`,
                        }}
                    >
                        <div className='font-bold text-[50px] text-white'>
                            {iconPositions < 0 ? (
                                <PassReaction />
                            ) : (
                                <HelloReaction />
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        ),
        [
            actionsSize,
            iconPositions,
            opacityVisible,
            type,
            visibleUser,
            isOpenModalDots,
            scrollY,
            translateY,
            buttons,
            isOpenModal,
            showLikeIcon,
        ]
    );
    if (!visibleUser && user) {
        return (
            <Modal
                isOpen={isOpenModal}
                onClose={handleCloseModal}
                setScrollPosition={setScrollPosition}
                isOpenModalDots={isOpenModalDots}
                setIsOpenModalDots={setIsOpenModalDots}
                buttons={buttons}
                className={twMerge(
                    'my-0 max-h-screen w-[726px] max-w-[100vw] select-none overflow-hidden overscroll-none rounded-none p-0 sm:my-0 sm:max-h-screen'
                )}
            >
                <div className='relative flex h-screen w-full flex-col items-start '>
                    <CloseButton
                        className='absolute top-8 left-4 z-40 text-[24px] text-white sm:text-[36px]'
                        onClose={() => handleCloseModal()}
                    />
                    <div className='relative h-[50%] w-full bg-clean-black sm:h-[650px]'>
                        <img
                            src={`${user.user?.avatar_url}?${userStore.home?.pub_urls_query}`}
                            alt='avatar'
                            className={twMerge(
                                'h-full w-full object-cover blur-2xl'
                            )}
                        />
                        <div className='absolute bottom-5 flex w-80 flex-col items-start gap-1 px-4'>
                            {filteredInterests.length > 0 && (
                                <MultiSelectItem
                                    key={filteredInterests[0]}
                                    item={interestsNames[filteredInterests[0]]}
                                    isSelected
                                    onClick={() => null}
                                    match
                                    className='h-6 cursor-default border-0'
                                />
                            )}
                            <span className='h-3 w-32 rounded-xl bg-gray-300' />
                            <span className='h-[6px] w-32 rounded-xl bg-gray-300' />
                        </div>
                    </div>
                    <span
                        className='z-40 w-full bg-white pt-8 pb-3'
                        style={{
                            fontSize: `${scaleFont(20)}px`,
                        }}
                    >
                        Want a free Reveal?
                    </span>
                    {/* <button
                        className='mx-auto w-[350px] rounded-lg bg-second-gold py-2 font-bold text-lg'
                        onClick={() => ''}
                    >
                        See who liked you
                    </button> */}
                </div>
            </Modal>
        );
    }
    return (
        <>
            {isOpenModal && (
                <div>
                    <ProfilePreviewModal
                        isOpen={isOpenModalDots}
                        setIsOpen={setIsOpenModalDots}
                        buttons={buttons}
                        className='h-full'
                        shadowCopy
                    />
                    <div
                        className={twMerge(
                            'absolute top-0 left-0 mx-[10px] mt-[15px] opacity-0 sm:mt-[5vh]',
                            fromNotification ? 'z-[60]' : 'z-50'
                        )}
                    >
                        <ProfilePreviewHeader
                            user={visibleUser}
                            modal
                            opacityVisible={opacityVisible}
                            width={modalWidth}
                        />
                    </div>
                    <div
                        className={twMerge(
                            'fixed bottom-[7vh] left-0 w-full',
                            fromNotification ? 'z-[60]' : 'z-50'
                        )}
                        style={
                            iconPositions
                                ? {
                                      transform: `rotate(${rotateValue}deg) translate(${translateXValue / 2}px, ${translateYValue}px)`,
                                  }
                                : {}
                        }
                    >
                        {user.type === 'super_smash' && (
                            <div
                                className='mx-auto mb-2 flex h-6 w-32 items-center justify-center gap-1 rounded-full bg-like py-1 text-sm text-white'
                                style={{
                                    opacity: opacityVisible,
                                    transform: `translateY(${translateY}px)`,
                                }}
                            >
                                <RedHeart className='h-4 w-4' fill='white' />
                                <span>sent you Like</span>
                            </div>
                        )}
                        {!showButtons ? (
                            type === 'basic' ? (
                                <div
                                    style={{
                                        zIndex: fromNotification ? 60 : 30,
                                        transform: `translateY(${translateY}px)`,
                                    }}
                                >
                                    <DiscoveryActions
                                        type={
                                            showLikeIcon
                                                ? 'top'
                                                : rotateValue > 0
                                                  ? 'right'
                                                  : 'left'
                                        }
                                        actionSize={actionsSize}
                                        translate={Math.abs(rotateValue)}
                                        handleActions={(side: string) =>
                                            handleActions(side, true)
                                        }
                                        showLikeIcon={showLikeIcon}
                                    />
                                </div>
                            ) : type === 'chat' ? (
                                <div className='fixed w-full opacity-0'>
                                    <ProfilePreviewActions
                                        type='chat'
                                        shadowCopy
                                        actionSize={actionsSize}
                                        className='mb-3'
                                        handleActions={() =>
                                            navigate(
                                                `${AppRoutes.Chat}/${visibleUser.id}`
                                            )
                                        }
                                    />
                                </div>
                            ) : null
                        ) : null}
                    </div>
                    {modalWidth && type === 'basic' && rotateValue === 0 && (
                        <div
                            className={twMerge(
                                'fixed top-[25vh] right-4 mr-2 h-24 w-1 rounded-xl bg-icon/30',
                                fromNotification ? 'z-[60]' : 'z-50'
                            )}
                            style={
                                window.innerWidth > 640
                                    ? {
                                          transform: `translateY(${translateY}px)`,
                                          left: `calc(50% + ${modalWidth / 2 - 12}px)`,
                                      }
                                    : {
                                          transform: `translateY(${translateY}px)`,
                                      }
                            }
                        >
                            <div
                                className='h-7 w-1 rounded-xl bg-white'
                                style={{
                                    transform: `translateY(${scrollY}px)`,
                                    transition: 'transform 0.1s ',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                                }}
                            />
                        </div>
                    )}
                    {!isOpenModalDots && scrollY > 60 && (
                        <div
                            style={{
                                bottom: `${201 - blockAndReportLocation}px`,
                            }}
                            className={twMerge(
                                'absolute left-0 h-10 w-full opacity-0 sm:hidden',
                                fromNotification ? 'z-[60]' : 'z-50'
                            )}
                            onClick={() => navigate(AppRoutes.Verification)}
                        />
                    )}
                    {!isOpenModalDots && scrollY > 60 && (
                        <div
                            className={twMerge(
                                'fixed left-0 hidden h-[70px] w-full justify-center opacity-0 sm:hidden',
                                fromNotification ? 'z-[60]' : 'z-50',
                                scrollY > 65 && 'flex'
                            )}
                            style={{
                                bottom: `${92 - blockAndReportLocation}px`,
                            }}
                        >
                            <Button
                                label='Block and Report'
                                onClick={() => {
                                    analyticsEvent('Card_Options_Press');
                                    setIsOpenModalDots(true);
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className='fixed inset-0 z-40 bg-gray-500 bg-opacity-50' />
            {renderProfilePreview()}
        </>
    );
};

export default LikedYouModals;
