import { updateUser } from '@/api/user';
import Heart from '@/assets/icons/heart-gold.svg?react';
import Button from '@/components/common/Button/Button';
import PageHeader from '@/components/common/PageHeader';
import SelectionBox from '@/components/common/SelectionBox';
import { UniversalButton } from '@/components/common/UniversalButton';
import MobileLayout from '@/components/layouts/MobileLayout';
import AgeRangeSelector from '@/components/searchSettings/AgeRangeSelector';
import ChangeLookingFor from '@/components/searchSettings/ChangeLookingFor';
import SettingsCheckbox, {
    CheckBoxData,
} from '@/components/settings/SettingsCheckbox';
import { Continent } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { getCountryNameByCode } from '@/utils/nationalityNames';
import { AppRoutes } from '@/utils/routes';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { getData } from '@/workers/sharedWorkerInstance';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { continentNames } from './locationFilter';

const checkboxes: CheckBoxData[] = [
    {
        text: 'Verified profiles only',
        propName: 'filter_verified_only',
        isNegativeValue: false,
    },
];

const SearchSettings = observer(() => {
    const navigate = useNavigate();
    const [userValue, setUserValue] = useState({
        match_gender: '',
        match_age_from: 19,
        match_age_to: 35,
        filter_verified_only: false,
        filter_continents: [] as Continent[],
        discover_near: false,
    });
    const [isOpenConfirmModal, setIsOpenConfirmModal] =
        useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (userStore?.home?.me?.settings) {
            const {
                match_gender,
                match_age_from,
                match_age_to,
                filter_verified_only,
                filter_continents = [],
                discover_near,
            } = userStore.home.me.settings;
            getData();
            if (
                discoveryStore.locationSettings.discover_near === true ||
                discoveryStore.locationSettings.discover_near === false
            ) {
                setUserValue({
                    match_gender:
                        discoveryStore.searchSettings.match_gender ||
                        match_gender,
                    match_age_from:
                        discoveryStore.searchSettings.match_age_from ||
                        match_age_from,
                    match_age_to:
                        discoveryStore.searchSettings.match_age_to ||
                        match_age_to,
                    filter_verified_only:
                        discoveryStore.searchSettings.filter_verified_only !==
                        null
                            ? discoveryStore.searchSettings.filter_verified_only
                            : filter_verified_only,
                    filter_continents:
                        discoveryStore.locationSettings.filter_continents,
                    discover_near:
                        discoveryStore.locationSettings.discover_near,
                });
            } else {
                setUserValue({
                    match_gender:
                        discoveryStore.searchSettings.match_gender ||
                        match_gender,
                    match_age_from:
                        discoveryStore.searchSettings.match_age_from ||
                        match_age_from,
                    match_age_to:
                        discoveryStore.searchSettings.match_age_to ||
                        match_age_to,
                    filter_verified_only:
                        discoveryStore.searchSettings.filter_verified_only !==
                        null
                            ? discoveryStore.searchSettings.filter_verified_only
                            : filter_verified_only,
                    filter_continents,
                    discover_near,
                });
            }
        }
    }, [userStore?.home?.me?.settings, discoveryStore.locationSettings]);

    const handleSubmit = async () => {
        try {
            userStore.updateSettings({
                discover_near: userValue.discover_near,
                filter_continents: userValue.filter_continents,
                match_gender: userValue.match_gender,
                match_age_from: userValue.match_age_from,
                match_age_to: userValue.match_age_to,
                filter_verified_only: userValue.filter_verified_only,
            });
            const { filter_continents } = userValue;
            const newValue = {
                ...userValue,
                filter_continents:
                    filter_continents.length === 6 ? [] : filter_continents,
            };

            await updateUser(
                localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                newValue
            );
            localStorage.removeItem(LOCAL_STORAGE.DiscoveryUsers);
            discoveryStore.setUsers([]);
            getData();
            navigate(AppRoutes.People);
        } catch (error) {
            console.log('Error submitting data:', error);
        }
    };

    useEffect(() => {
        if (!userStore.home?.me?.settings) {
            return;
        }
        const {
            match_gender,
            match_age_from,
            match_age_to,
            filter_verified_only,
            filter_continents,
            discover_near,
        } = userStore.home.me.settings;
        const isDirtyContinents =
            userValue.filter_continents.length === filter_continents.length &&
            userValue.filter_continents.every((continent) =>
                filter_continents.includes(continent)
            );
        const isWorldwide =
            userValue.filter_continents.length === 6 &&
            filter_continents.length === 0;
        if (
            userValue.match_gender !== match_gender ||
            userValue.match_age_from !== match_age_from ||
            userValue.match_age_to !== match_age_to ||
            userValue.filter_verified_only !== filter_verified_only ||
            (isWorldwide ? false : !isDirtyContinents) ||
            userValue.discover_near !== discover_near
        ) {
            setIsDirty(true);
        } else setIsDirty(false);
    }, [userValue]);
    const handleGoBack = () => {
        discoveryStore.clearSearchSettings();
        discoveryStore.clearLoactionSettings();
        navigate(AppRoutes.People);
    };
    const { isMobile } = useScreenDetector();
    return (
        <MobileLayout className='w-full overflow-y-auto bg-bg-gray p-0'>
            <PageHeader
                close
                goBack={() =>
                    isDirty ? setIsOpenConfirmModal(true) : handleGoBack()
                }
                title='Search filters'
            />
            <div className='flex h-full flex-col px-4'>
                <SelectionBox title="I'm interested in">
                    <ChangeLookingFor
                        userValue={userValue}
                        setUserValue={setUserValue}
                    />
                </SelectionBox>
                <SelectionBox title='Age'>
                    <AgeRangeSelector
                        userValue={userValue}
                        setUserValue={setUserValue}
                    />
                </SelectionBox>
                <SelectionBox title='Location'>
                    <div className='flex w-full flex-col gap-1'>
                        <UniversalButton
                            text={
                                userValue.discover_near
                                    ? `${getCountryNameByCode(userStore?.userData?.country_code || '')} `
                                    : userValue.filter_continents.length === 6
                                      ? 'Worldwide'
                                      : userValue.filter_continents.length > 0
                                        ? userValue.filter_continents
                                              .map(
                                                  (continent) =>
                                                      continentNames[continent]
                                              )
                                              .join(', ')
                                        : 'Worldwide'
                            }
                            onClick={() => navigate(AppRoutes.Location)}
                        />
                        <p
                            className='mx-4 text-[15px]'
                            style={{
                                fontSize: `${scaleFont(12)}px`,
                                lineHeight: `${scaleFont(14)}px`,
                            }}
                        >
                            Choose where you want to see profiles in Cards
                        </p>
                    </div>
                </SelectionBox>
                <div
                    className='relative flex flex-col items-center rounded-xl border-2 border-second-gold bg-primary-gradient p-4'
                    style={{
                        marginTop: isMobile ? `${scaleSize(24)}px` : 40,
                    }}
                >
                    <Heart
                        className='absolute z-10'
                        style={{
                            top: `-${scaleSize(25)}px`,
                        }}
                        width={`${scaleSize(50)}px`}
                        height={`${scaleSize(50)}px`}
                    />
                    <div className='flex w-full flex-col gap-2'>
                        <span
                            className='text-white text-xs md:text-basic'
                            style={{
                                fontSize: `${isMobile ? scaleFont(12) : 16}px`,
                            }}
                        >
                            Advanced filter
                        </span>
                        {checkboxes.map((checkbox, index) => (
                            <div key={checkbox.propName}>
                                <SettingsCheckbox
                                    checkboxData={checkbox}
                                    checked={!!userValue.filter_verified_only}
                                    subscribed={
                                        userStore?.home?.me?.subscription
                                            ?.type !== 'none'
                                    }
                                    setUserValue={setUserValue}
                                    withoutReqest
                                    onlyWithPlus
                                />
                                {index === 0 && (
                                    <span
                                        className='text-white text-xs md:text-basic'
                                        style={{
                                            fontSize: `${isMobile ? scaleFont(10) : 14}px`,
                                        }}
                                    >
                                        See only verified profiles in Cards
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flex h-full items-end pb-2'>
                    <Button
                        className={twMerge(
                            'h-fit',
                            isDirty ? 'opacity-100' : 'opacity-0'
                        )}
                        variant='primary'
                        label='Apply'
                        style={{
                            fontSize: `${scaleFont(16)}px`,
                        }}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            {isOpenConfirmModal && (
                <div className='fixed inset-0 z-50 flex items-center justify-center'>
                    <div
                        className='fixed inset-0 bg-black opacity-50'
                        onClick={() => setIsOpenConfirmModal(false)}
                    ></div>
                    <div className='relative z-10 flex w-[300px] flex-col gap-16 rounded-xl bg-white p-4 shadow-lg'>
                        <h2>Do you want to save changes?</h2>
                        <div className='flex justify-end gap-5'>
                            <button
                                className='font-semibold text-primary'
                                onClick={handleSubmit}
                            >
                                YES
                            </button>
                            <button
                                className='font-semibold text-primary'
                                onClick={() => {
                                    setIsOpenConfirmModal(false);
                                    handleGoBack();
                                }}
                            >
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </MobileLayout>
    );
});

export default SearchSettings;
