import React, { useEffect, useRef, useState } from 'react';

import { signIn } from '@/api/auth';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import Modal from '@/components/modals/Modal';
import { useCodeSignIn } from '@/hooks/useCodeSignIn';
import { useCountdown } from '@/hooks/useCountDown';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { EMAIL_SIGN_IN_STEPS } from '@/pages/EmailSignIn';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { secondsToMinutes } from '@/utils/secondsToMinutes';
import { AUTH_EVENTS, analyticsEvent } from '@shared/services/analytics';

import { goals } from '../userInfoSteps/GoalStep';

export const INITIAL_CODE_COUNTDOWN = 3 * 60;

interface CodeStepProps {
    setStep: (step: EMAIL_SIGN_IN_STEPS) => void;
    onNext?: (isShorterFlow?: boolean) => void;
    onBack?: () => void;
    isLite?: boolean;
}

interface ResendCodeButtonProps {
    onResendCode: () => void;
    setTriesLeft?: (tries: number) => void;
}

const ResendCodeButton: React.FC<ResendCodeButtonProps> = ({
    onResendCode,
}) => (
    <button
        className='font-semibold text-white underline'
        onClick={onResendCode}
        style={{
            fontSize: `${scaleFont(15)}px`,
        }}
    >
        Send again
    </button>
);

const NoAttempts: React.FC<ResendCodeButtonProps> = ({
    onResendCode,
    setTriesLeft,
}) => {
    const existingCountdownStartedAt = localStorage.getItem(
        LOCAL_STORAGE.CodeCountdownStartedAt
    );
    let countDownValue = INITIAL_CODE_COUNTDOWN;
    if (!existingCountdownStartedAt) {
        localStorage.setItem(
            LOCAL_STORAGE.CodeCountdownStartedAt,
            String(Date.now())
        );
    } else {
        countDownValue =
            INITIAL_CODE_COUNTDOWN -
            Math.ceil((Date.now() - +existingCountdownStartedAt) / 1000);
    }
    const { countdown } = useCountdown(countDownValue);
    if (countdown < 0) {
        localStorage.clear();
        if (setTriesLeft) {
            setTriesLeft(5);
        }
    }
    return (
        <div className='mt-4 text-center'>
            <span>Your attempts are over </span>
            {countdown > 0 ? (
                <p className='font-bold'>
                    Repeat in {secondsToMinutes(countdown)}
                </p>
            ) : (
                <ResendCodeButton onResendCode={onResendCode} />
            )}
        </div>
    );
};

const CodeStep: React.FC<CodeStepProps> = ({
    setStep,
    onNext,
    onBack,
    isLite,
}) => {
    const email = localStorage.getItem(LOCAL_STORAGE.EmailSignIn) || '';
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [isInvalidCodeModalOpen, setIsInvalidCodeModalOpen] = useState(false);
    const [isNoAttemptsModalOpen, setIsNoAttemptsModalOpen] = useState(false);
    const { countdown, setCountdown } = useCountdown(30);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleNext = () => {
        if (isLite && onNext) {
            const currentGoal = localStorage.getItem('goal');
            const shorterFlow =
                currentGoal !== goals[0].value &&
                currentGoal !== goals[1].value;
            onNext(shorterFlow);
        }
    };
    const {
        code,
        handleCodeChange,
        handleCodeSubmit,
        isValidCode,
        setIsValidCode,
        triesLeft,
        setTriesLeft,
    } = useCodeSignIn(email, setIsLoading, handleNext, isLite);
    useEffect(() => {
        if (code.length === 6) {
            inputsRef.current.forEach((input) => {
                if (input) {
                    input.blur();
                }
            });
            handleCodeSubmit();
        }
    }, [code, handleCodeSubmit]);
    useEffect(() => {
        if (inputsRef.current[0]) {
            inputsRef.current[0].focus();
        }
    }, []);

    useEffect(() => {
        if (!isValidCode && triesLeft > 0) {
            setIsInvalidCodeModalOpen(true);
        }
    }, [isValidCode, triesLeft]);

    useEffect(() => {
        if (triesLeft === 0) {
            setIsNoAttemptsModalOpen(true);
        }
    }, [triesLeft]);

    const handleInputChange = (i: number, value: string) => {
        if (/^[0-9]$/.test(value)) {
            handleCodeChange(i, value);
            if (i < 5 && inputsRef.current[i + 1]) {
                inputsRef.current[i + 1]?.focus();
            }
        } else if (value === '') {
            handleCodeChange(i, value);
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        i: number
    ) => {
        if (e.key === 'Backspace' && !code[i]) {
            if (i > 0) {
                inputsRef.current[i - 1]?.focus();
                handleCodeChange(i - 1, '');
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d{6}$/.test(paste)) {
            paste.split('').forEach((char, i) => {
                handleCodeChange(i, char);
                inputsRef.current[i]?.focus();
            });
            e.preventDefault();
        }
    };

    const onResendCode = async () => {
        analyticsEvent(AUTH_EVENTS.EMAIL_CONFIRM_RESEND_PRESS);

        setIsLoading(true);

        try {
            await signIn(email);
            setCountdown(30);
            setIsValidCode(true);
            setTriesLeft(1);
        } catch (error: any) {
            analyticsEvent('EmailRequestError', {
                error: error.response?.data?.m || '',
            });
            if (error.response?.data?.info) {
                const { tries_left, retry_left } = error.response.data.info;
                localStorage.setItem(
                    LOCAL_STORAGE.CodeCountdownStartedAt,
                    String(Date.now() + retry_left - 30 * 60 * 1000)
                );
                localStorage.setItem(
                    LOCAL_STORAGE.CodeTriesLeft,
                    String(tries_left)
                );
                setTriesLeft(tries_left);
                setIsValidCode(false);
            }
        }
        setIsLoading(false);
    };

    const onGoBack = () => {
        if (isLite && onBack) {
            onBack();
        } else setStep(EMAIL_SIGN_IN_STEPS.EMAIL);
    };
    const { isMobile } = useScreenDetector();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const isOver = secondsToMinutes(
            INITIAL_CODE_COUNTDOWN -
                Math.ceil(
                    (Date.now() -
                        +localStorage.getItem(
                            LOCAL_STORAGE.CodeCountdownStartedAt
                        )!) /
                        1000
                )
        );
        if (!isOver) {
            localStorage.removeItem(LOCAL_STORAGE.CodeCountdownStartedAt);
            localStorage.removeItem(LOCAL_STORAGE.CodeTriesLeft);
            setIsNoAttemptsModalOpen(false);
            setIsValidCode(true);
            setTriesLeft(1);
        }
    }, []);
    return (
        <UserDataFlowLayout
            disableNextButton={code.length < 6 || !triesLeft}
            onBack={onGoBack}
            onNext={handleCodeSubmit}
            isLoading={isLoading}
        >
            <div className='flex flex-col gap-12'>
                <h1
                    className='font-extrabold'
                    style={{
                        fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                    }}
                >
                    Enter confirmation code
                </h1>
                <div className='grid max-w-[435px] grid-cols-6 gap-3'>
                    {[...Array(6)].map((_, i) => (
                        <input
                            key={i}
                            ref={(el) => {
                                inputsRef.current[i] = el;
                            }}
                            className='h-[50px] w-[50px] rounded-lg bg-bg-gray p-3 text-center font-bold text-black text-xl focus:outline-none md:h-[60px] md:w-[60px]'
                            type={isMobile ? 'number' : 'text'}
                            maxLength={1}
                            pattern='[0-9]*'
                            value={code[i] || ''}
                            onChange={(e) =>
                                handleInputChange(i, e.target.value)
                            }
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            onPaste={i === 0 ? handlePaste : undefined}
                            disabled={!triesLeft}
                        />
                    ))}
                </div>
            </div>
            {triesLeft ? (
                <div
                    className='text-center'
                    style={{
                        marginTop: scaleSize(20),
                        fontSize: `${scaleFont(15)}px`,
                    }}
                >
                    <span></span>
                    {countdown > 0 ? (
                        <span>
                            We’ve sent you a code. You can send another code in{' '}
                            {countdown} sec
                        </span>
                    ) : (
                        <>
                            <span>Not received? </span>
                            <ResendCodeButton onResendCode={onResendCode} />
                        </>
                    )}
                </div>
            ) : !triesLeft ? (
                <NoAttempts
                    onResendCode={onResendCode}
                    setTriesLeft={setTriesLeft}
                />
            ) : null}

            <Modal
                isOpen={isInvalidCodeModalOpen}
                title='Your code is incorrect'
                cancelText='Okay'
                onCancel={() => {
                    localStorage.removeItem(LOCAL_STORAGE.Code);
                    setIsInvalidCodeModalOpen(false);
                }}
                text={`You have ${triesLeft} ${triesLeft === 1 ? 'try' : 'tries'} 
                    left`}
            />
            <Modal
                isOpen={isNoAttemptsModalOpen}
                cancelText='Okay'
                onCancel={() => setIsNoAttemptsModalOpen(false)}
                text={`You have used all tries, please try again in 
                    ${secondsToMinutes(
                        INITIAL_CODE_COUNTDOWN -
                            Math.ceil(
                                (Date.now() -
                                    +localStorage.getItem(
                                        LOCAL_STORAGE.CodeCountdownStartedAt
                                    )!) /
                                    1000
                            )
                    )}`}
            />
        </UserDataFlowLayout>
    );
};

export default CodeStep;
