import * as Sentry from '@sentry/react';

export const initialize: typeof Sentry.init = ({ ...config }) => {
    return Sentry.init({
        sampleRate: 0.5, // 50% of all transactions
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        attachStacktrace: true,
        integrations: [
            Sentry.browserTracingIntegration({
                traceXHR: false,
                traceFetch: false,
            }),
            Sentry.replayIntegration(),
        ],
        ...config,
    });
};

export const setUser = Sentry.setUser;
