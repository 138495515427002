import { ShowcaseItem } from '@/entities/payment';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import Copy from '@assets/icons/copy-icon.svg?react';
import SuccessIcon from '@assets/icons/success-credit-card.svg?react';
import {
    durationSubscription,
    durationSubscriptionOrdinal,
} from '@utils/period.ts';
import { AppRoutes } from '@utils/routes.ts';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Button from '../common/Button/Button';

interface SuccessAreaProps {
    showcaseItem: ShowcaseItem;
    orderId: string;
    subscribeType: boolean;
    price: number;
}

const SuccessArea: React.FC<SuccessAreaProps> = ({
    showcaseItem,
    orderId,
    subscribeType,
    price,
}) => {
    const navigate = useNavigate();
    const handleStarted = () => {
        localStorage.removeItem(LOCAL_STORAGE.BlockRefresh);
        navigate(-1);
    };
    return (
        <div className='mt-4 flex flex-col items-center gap-4'>
            <SuccessIcon />
            <p>Order number</p>
            <div className='flex'>
                <span
                    className='rounded-l-[10px] bg-bg-gray p-2'
                    style={{
                        fontSize: scaleFont(14),
                    }}
                >
                    {orderId}
                </span>
                <div className='relative min-w-10 rounded-r-[10px] bg-nav-dark-gray'>
                    <Copy
                        fill='white'
                        className='-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 cursor-pointer'
                        onClick={() => copyToClipboard(orderId)}
                    />
                </div>
            </div>
            <Button
                className={twMerge(
                    ' px-8',
                    subscribeType ? 'bg-gold-gradient' : 'bg-like text-white'
                )}
                label='Get Started'
                onClick={handleStarted}
            />
            <div
                className='mb-4 text-center text-stroke'
                style={{
                    fontSize: scaleFont(13),
                }}
            >
                <p>
                    {subscribeType
                        ? `You are enrolling in a ${durationSubscriptionOrdinal(showcaseItem)} subscription to https://bumpy.app service. You agree to be billed USD ${price} per ${durationSubscription(showcaseItem)} until you cancel the subscription. Payments will be charged from the card you specified here. You can cancel your subscription in your settings.`
                        : 'Your purchase is complete. You can view the terms of service of your purchase at '}
                </p>
                <a
                    href={`https://bumpy.app${AppRoutes.TermsOfService}`}
                    className='text-primary'
                    target='_blank'
                    rel='noreferrer'
                >
                    https://bumpy.app{AppRoutes.TermsOfService}
                </a>
                {subscribeType && (
                    <p>To cancel, visit your account settings.</p>
                )}
            </div>
        </div>
    );
};

export default SuccessArea;
