import CheckboxInput from '@/components/common/CheckBoxInput';
import PageHeader from '@/components/common/PageHeader';
import SelectionBox from '@/components/common/SelectionBox';
import MobileLayout from '@/components/layouts/MobileLayout';
import { Continent } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import userStore from '@/entities/user/store';
import { getCountryNameByCode } from '@/utils/nationalityNames';
import { AppRoutes } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const continentNames: Record<Continent, string> = {
    AF: 'Africa',
    AS: 'Asia',
    EU: 'Europe',
    NA: 'North America',
    OC: 'Oceania',
    SA: 'South America',
};

const LocationFilter = observer(() => {
    const navigate = useNavigate();
    const continents: Continent[] = ['AF', 'AS', 'EU', 'NA', 'OC', 'SA'];

    const [selectedContinents, setSelectedContinents] = useState<Continent[]>(
        userStore?.home?.me?.settings?.filter_continents || []
    );
    const [selectedOption, setSelectedOption] = useState<'country' | 'world'>(
        userStore.home?.me?.settings?.discover_near ? 'country' : 'world'
    );

    useEffect(() => {
        if (selectedContinents.length === 0 && selectedOption !== 'country') {
            handleOptionChange('world');
        }
    }, [selectedContinents, selectedOption]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            discoveryStore.locationSettings?.discover_near === true ||
            discoveryStore.locationSettings?.discover_near === false
        ) {
            setSelectedOption(
                discoveryStore.locationSettings?.filter_option || 'world'
            );
            setSelectedContinents(
                discoveryStore.locationSettings?.filter_continents || []
            );
        }
    }, [discoveryStore.locationSettings]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleChange = useCallback(
        (continent: Continent) => {
            setSelectedContinents((prevSelected) => {
                const updatedContinents = prevSelected.includes(continent)
                    ? prevSelected.filter((c) => c !== continent)
                    : [...prevSelected, continent];

                const newOption =
                    updatedContinents.length > 0 ? 'world' : selectedOption;
                setSelectedOption(newOption);

                discoveryStore.setLoactionSettings({
                    filter_continents: updatedContinents,
                    filter_option: 'world',
                    discover_near: false,
                });

                return updatedContinents;
            });
        },
        [selectedContinents, selectedOption]
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleOptionChange = useCallback(
        (option: 'country' | 'world') => {
            setSelectedOption(option);
            setSelectedContinents(option === 'world' ? continents : []);
            discoveryStore.setLoactionSettings({
                filter_continents: option === 'world' ? continents : [],
                filter_option: option,
                discover_near: option === 'country',
            });
        },
        [continents]
    );

    return (
        <MobileLayout className='w-full overflow-y-auto bg-bg-gray p-0'>
            <PageHeader
                goBack={() => navigate(AppRoutes.SearchSettings)}
                title='Location'
            />
            <div className='flex flex-col px-4'>
                <SelectionBox title='Range'>
                    <div className='flex w-full flex-col'>
                        <CheckboxInput
                            checked={selectedOption === 'country'}
                            onChange={() => handleOptionChange('country')}
                            label={
                                getCountryNameByCode(
                                    userStore?.userData?.country_code || ''
                                ) || ''
                            }
                            withCheckbox
                            blueBorderInput
                            type='radio'
                            fromSSS
                            className='border-[0.5px] border-stroke'
                        />
                        <CheckboxInput
                            checked={selectedContinents.length > 5}
                            onChange={() => handleOptionChange('world')}
                            label='Worldwide'
                            withCheckbox
                            blueBorderInput
                            type='radio'
                            fromSSS
                            className='border-[0.5px] border-stroke'
                        />
                    </div>
                </SelectionBox>
            </div>

            <div className='flex flex-col px-4'>
                <SelectionBox title='Continents'>
                    <div className='flex w-full flex-col'>
                        {continents.map((continent) => (
                            <CheckboxInput
                                key={continent}
                                checked={selectedContinents.includes(continent)}
                                onChange={() => handleChange(continent)}
                                label={continentNames[continent]}
                                withCheckbox
                                blueBorderInput
                                type='checkbox'
                                fromSSS
                                className='border-[0.5px] border-stroke'
                            />
                        ))}
                    </div>
                </SelectionBox>
            </div>
        </MobileLayout>
    );
});

export default LocationFilter;
