import TopRated from '@/assets/icons/top-rated.svg?react';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import MobileStoreButtons from './MobileStoreButtons';

const RatedSection = () => {
    const { isMobile } = useScreenDetector();
    return (
        <div className='flex w-screen flex-col items-center gap-12 bg-light-primary sm:h-[386px] sm:flex-row sm:justify-center'>
            <TopRated className='mt-16 sm:mt-0' />
            <div className='mb-16 flex max-w-[95vw] flex-col gap-8 sm:max-w-[525px]'>
                <span
                    className='w-full text-center font-semibold sm:text-start '
                    style={{
                        fontSize: scaleFont(isMobile ? 28 : 36),
                    }}
                >
                    Designed to help you <br /> to be found around the world
                </span>
                <MobileStoreButtons />
            </div>
        </div>
    );
};

export default RatedSection;
