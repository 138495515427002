import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';
import { LOGIN_MEMBERS_CARD } from './LoginConstant';
import RegisterMembersCard from './RegisterMembersCard';

interface MembersSectionProps {
    handleSignInWithEmail: () => void;
}

const MembersSection: React.FC<MembersSectionProps> = ({
    handleSignInWithEmail,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <div
            className='h-fit bg-light-primary text-center'
            style={{
                paddingTop: scaleSize(40),
                paddingBottom: scaleSize(40),
                paddingLeft: scaleSize(16),
                paddingRight: scaleSize(16),
            }}
        >
            <span
                className='font-bold'
                style={{
                    fontSize: scaleFont(isMobile ? 24 : 32),
                }}
            >
                From a Bump to Forever
            </span>
            <div
                className='flex w-full flex-col items-center'
                style={{
                    marginTop: scaleSize(40),
                    gap: scaleSize(48),
                }}
            >
                <div
                    className='flex flex-col sm:flex-row'
                    style={{
                        gap: scaleSize(isMobile ? 48 : 32),
                    }}
                >
                    {LOGIN_MEMBERS_CARD.map((card) => (
                        <RegisterMembersCard
                            key={card.name}
                            image={card.image}
                            text={card.text}
                            name={card.name}
                            firstCode={card.firstCode}
                            secondCode={card.secondCode}
                        />
                    ))}
                </div>
                <button
                    className='flex h-[50px] w-[80vw] max-w-[400px] items-center justify-center rounded-lg border border-primary bg-primary px-4 py-3 font-bold text-secondary shadow-sm md:w-[96vw]'
                    onClick={handleSignInWithEmail}
                >
                    Try Now
                </button>
            </div>
        </div>
    );
};

export default MembersSection;
