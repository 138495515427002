import { CredentialResponse } from '@react-oauth/google';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { googleSignIn } from '@/api/auth';
import AdvantageSection from '@/components/logedOut/AdvantageSection';
import AppsSection from '@/components/logedOut/AppsSection';
import AuthSection from '@/components/logedOut/AuthSection';
import Footer from '@/components/logedOut/Footer';
import MembersSection from '@/components/logedOut/MembersSection';
import RatedSection from '@/components/logedOut/RatedSetcion';
import StepsSection from '@/components/logedOut/StepsSection';
import { handleLoginSuccess } from '@/utils/auth';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import {
    AUTH_EVENTS,
    SCREENS,
    analyticsEvent,
    analyticsScreen,
    analyticsSignIn,
    defaultRegistrationTrackScreen,
    trackDefaultRegistrationEvent,
} from '@shared/services/analytics';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');
    const handleSignInWithEmail = () => {
        analyticsEvent(AUTH_EVENTS.SIGN_IN_WITH_EMAIL_PRESSED);
        trackDefaultRegistrationEvent(AUTH_EVENTS.SIGN_IN_WITH_EMAIL_PRESSED);
        navigate(AppRoutes.SignInEmail);
    };

    useEffect(() => {
        analyticsScreen(SCREENS.SIGN);
        defaultRegistrationTrackScreen(SCREENS.SIGN);
    }, []);

    useEffect(() => {
        if (authToken) {
            navigate(AppRoutes.People);
        }
    }, [authToken, navigate]);

    const handleClick = () => {
        navigate(AppRoutes.SignInEmail);
    };
    const googleLogin = async (response: CredentialResponse) => {
        if (!response.credential) return;
        try {
            const userResponse = await googleSignIn(
                response.credential,
                null,
                navigate
            );

            handleLoginSuccess(userResponse.result, navigate);
            analyticsSignIn('google');
            trackDefaultRegistrationEvent(AUTH_EVENTS.SIGN_IN, {
                method: 'google',
            });
        } catch (err: any) {
            if (
                err?.response?.data?.m &&
                err.response?.data?.m === 'e_user_not_registered'
            ) {
                localStorage.setItem(
                    LOCAL_STORAGE.GoogleToken,
                    response.credential
                );
            }
        }
    };
    return (
        <div className='overflow-y-auto overscroll-none'>
            <AuthSection
                googleLogin={googleLogin}
                handleSignInWithEmail={handleSignInWithEmail}
            />
            <RatedSection />
            <AdvantageSection handleSignInWithEmail={handleClick} />
            <MembersSection handleSignInWithEmail={handleClick} />
            <StepsSection handleSignInWithEmail={handleClick} />
            <AppsSection />
            <Footer />
            <div
                className='h-fit w-screen bg-light-primary py-7 text-center text-nav-dark-gray'
                style={{
                    fontSize: scaleFont(12),
                }}
            >
                <p>
                    Copyright © 2025 MV Bumpy Group Limited, all rights
                    reserved.
                </p>
                <p>Strovolou 77, 4th floor, office 401 2018 Nicosia Cyprus</p>
            </div>
        </div>
    );
};

export default Login;
