import { validateAbout } from '@/api/auth';
import CheckMark from '@/assets/icons/check-mark.svg?react';
import ErrorOutlined from '@/assets/icons/error-outlined.svg?react';
import TextInput from '@/components/common/TextInput/TextInput';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import ButtonWithModalTips from '@/components/modals/ButtonWithModalTips';
import Modal from '@/components/modals/Modal';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { isValidName } from '@/utils/validation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import StepHeader from './StepHeader';
import { UserInfoStepProps } from './userInfoConfig';

const NameStep: React.FC<UserInfoStepProps> = ({ onNext, isLite }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [name, setName] = useState(
        () => localStorage.getItem('first_name') || ''
    );
    const [isValidNameField, setIsValidNameField] = useState(false);
    const [openInvalidName, setOpenInvalidName] = useState(false);
    const analyticsName = 'StepFirstName';
    useEffect(() => {
        setIsValidNameField(isValidName(name));
    }, [name]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const onNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newName = e.target.value;
            setName(newName);
            localStorage.setItem('first_name', newName);
        },
        []
    );
    const handleConfirmName = async () => {
        try {
            const response = await validateAbout(name);
            if (response.result.valid) {
                onNext();
            } else {
                setOpenInvalidName(true);
                localStorage.removeItem('first_name');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleConfirmName();
        }
    };
    const { isMobile } = useScreenDetector();
    return (
        <UserDataFlowLayout
            disableNextButton={!isValidNameField}
            onNext={handleConfirmName}
            withBarProgress
        >
            <div className='flex h-full flex-col'>
                <StepHeader
                    title='What is your name?'
                    description='This name will be seen by other users'
                    barProgress={isLite ? 45 : 10}
                />
                <div
                    className='relative px-4'
                    style={{
                        marginTop: `${scaleSize(30)}px`,
                        height: `${scaleSize(50)}px`,
                    }}
                >
                    <TextInput
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={handleKeyDown}
                        placeholder='Enter your name here'
                        style={{
                            paddingTop: `${scaleSize(12)}px`,
                            paddingBottom: `${scaleSize(12)}px`,
                            fontSize: `${scaleFont(18)}px`,
                        }}
                    />
                    {name &&
                        (isValidNameField ? (
                            <CheckMark
                                fill='#19C809'
                                className='absolute top-3 right-6'
                                width={24}
                                height={24}
                            />
                        ) : (
                            <ErrorOutlined
                                className='absolute top-3 right-6'
                                width={24}
                                height={24}
                            />
                        ))}
                    <ButtonWithModalTips
                        className='mt-0 font-semibold text-nav-dark-gray'
                        analyticsName={analyticsName}
                    />
                    {name && !isValidNameField && (
                        <p
                            className='mt-1 ml-4 h-[15px] text-white'
                            style={{
                                fontSize: `${isMobile ? scaleFont(12) : 16}px`,
                            }}
                        >
                            The name field cannot contain numbers or symbols
                        </p>
                    )}
                </div>
            </div>
            <Modal
                isOpen={openInvalidName}
                title='Warning!'
                cancelText='Okay'
                onCancel={() => {
                    setName('');
                    setOpenInvalidName(false);
                }}
                text='Your info violated Community Guidelines. For more details,
                    please see profile safety tips.'
            />
        </UserDataFlowLayout>
    );
};

export default NameStep;
