import Payment, { ClientSdkInstance, CardMessage } from '@solidgate/react-sdk';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { getMerchantData } from '@/api/user';
import Lock from '@/assets/icons/lock.svg?react';
import { ShowcaseItem } from '@/entities/payment';
import userStore from '@/entities/user/store';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import { validateCard } from '@shared/api/card';
import { useModal } from '@shared/hooks/useModal';
import { includes } from '@shared/lib/array';
import { CardBrand } from '@shared/lib/payment';
import { analyticsPurchase } from '@shared/services/analytics';

import DeclineArea from './DeclineArea';
import PaymentCard from './PaymentCard';
import PaymentRestrictedModal, {
    PaymentRestrictedModalReason,
} from './PaymentRestrictedModal';
import SuccessArea from './SuccessArea';

const ALLOWED_CARD_BRANDS = [CardBrand.Visa, CardBrand.Mastercard];

const isAllowedCardBrand = (cardBrand: string) => {
    return includes(ALLOWED_CARD_BRANDS, cardBrand.toLowerCase());
};

interface PaymentMethodProps {
    isOpen: boolean;
    showcaseItem: ShowcaseItem;
    subscribeType: boolean;
    isSuccess: boolean;
    setIsSuccess: (type: boolean) => void;
    basePrice: number;
    authToken?: string;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
    isOpen,
    showcaseItem,
    subscribeType,
    isSuccess,
    setIsSuccess,
    basePrice,
    authToken,
}) => {
    const pageContainer = useRef<any>(null);
    const hasRun = useRef(false);
    const [isMounted, setIsMounted] = useState(false);
    const [form, setForm] = useState<ClientSdkInstance | null>(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [width, setWidth] = useState<number>(694);
    const [buttonText, setButtonText] = useState('Buy now');
    const [lastValidatedCard, setLastValidatedCard] = useState<
        CardMessage['card'] | null
    >(null);
    const {
        value: restrictedReason,
        open: openedRestrictedCardBrandModal,
        show: showRestrictedCardBrandModal,
        hide: hideRestrictedCardBrandModal,
    } = useModal<PaymentRestrictedModalReason>();
    const [orderId, setOrderId] = useState<string>('');
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [merchantData, setMerchantData] = useState<any>();

    const price = Number.parseFloat(
        (showcaseItem?.default_price_usd / 100).toFixed(2)
    );
    const sale =
        100 -
        Math.floor(
            (showcaseItem?.default_price_usd /
                basePrice /
                showcaseItem?.value) *
                100
        );

    const getMerchant = useCallback(async () => {
        const merData = await getMerchantData(
            authToken || localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
            showcaseItem?.sku
        );
        setMerchantData({
            merchant: merData.result.merchant,
            signature: merData.result.signature,
            paymentIntent: merData.result.payment_intent,
        });
    }, [showcaseItem?.sku, authToken]);

    const handleButtonClick = async () => {
        // WTF logic by button text
        if (buttonText !== 'Buy now') {
            await getMerchant();
            setButtonText('Buy now');
            return;
        }

        if (!isAllowedCardBrand(lastValidatedCard?.brand || '')) {
            showRestrictedCardBrandModal('notAllowedCardBrand');
            return;
        }

        try {
            await validateCard({
                bin: lastValidatedCard?.bin || '',
                token: localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
            });
        } catch {
            showRestrictedCardBrandModal('validationFailed');
            return;
        }

        form?.submit();
    };

    useEffect(() => {
        if (!pageContainer.current) {
            return;
        }
        const { clientWidth } = pageContainer.current;
        if (clientWidth !== 726) {
            setWidth(clientWidth - 32);
        }
        const getData = async () => {
            try {
                await getMerchant();
            } catch (error) {
                console.error('Error:', error);
            }
        };
        if (!hasRun.current) {
            hasRun.current = true;
            getData();
        }
    }, [getMerchant]);

    const handleOnSuccess = (e: any) => {
        if (e.type === 'success') {
            if (!subscribeType) {
                userStore.setMeData({
                    ...userStore.home.me,
                    balance: {
                        ...userStore.home.me.balance,
                        super_smashes:
                            userStore.home.me.balance.super_smashes +
                            showcaseItem?.count,
                    },
                });
            } else {
                if (!authToken) {
                    userStore.setSubscribtionData({
                        ...userStore.home.me.subscription,
                        last_duration_unit: showcaseItem?.unit_id,
                        last_duration_value: showcaseItem?.count,
                        last_price_usd: showcaseItem?.default_price_usd,
                        last_status: 'active',
                        until:
                            (userStore.home.me.subscription.until ||
                                Date.now()) + 100000000,
                    });
                }
            }
            setCustomerEmail(e.order.customer_email || '');
            setOrderId(e.order.order_id || '');
            setIsSuccess(true);
            localStorage.removeItem(LOCAL_STORAGE.BlockRefresh);
            userStore.setSubscribtionType('plus');
            analyticsPurchase(price, showcaseItem?.id, showcaseItem?.sku);
        }
    };

    const handleOnReadyPaymentInstance = useCallback(
        (form: ClientSdkInstance) => {
            setForm(form);
        },
        []
    );

    const handleValidatedCard = (message: CardMessage) => {
        const { card } = message;

        setLastValidatedCard(card);
    };

    const handleOnInteraction = (message: any) => {
        const { cardForm } = message;

        setIsFormValid(cardForm.isValid);
    };

    const handleMounted = useCallback(() => {
        if (localStorage.getItem(LOCAL_STORAGE.BlockRefresh) !== 'true') {
            localStorage.setItem(LOCAL_STORAGE.BlockRefresh, 'true');
        }
        setIsMounted(true);
    }, []);

    const handleOrderStatus = (e: { response: { error?: any } }) => {
        localStorage.removeItem(LOCAL_STORAGE.BlockRefresh);
        if (e.response.error) {
            setButtonText('Try again');
        }
    };

    if (!isOpen || !showcaseItem) {
        return null;
    }

    return (
        <div
            className='flex h-full flex-col gap-4 overflow-auto px-4'
            ref={pageContainer}
        >
            {isSuccess && (
                <p
                    className={twMerge(
                        'text-center text-nav-dark-gray',
                        authToken && 'pt-8'
                    )}
                >
                    {`An email receipt is on its way to ${customerEmail}`}
                </p>
            )}
            <PaymentCard
                showcaseItem={showcaseItem}
                subscribeType={subscribeType}
                price={price}
                sale={sale}
            />
            {merchantData === undefined ? null : !isSuccess ? (
                buttonText === 'Try again' ? (
                    <DeclineArea price={price} />
                ) : (
                    <Payment
                        merchantData={merchantData}
                        width={`${width}px`}
                        onSuccess={handleOnSuccess}
                        onOrderStatus={handleOrderStatus}
                        onReadyPaymentInstance={handleOnReadyPaymentInstance}
                        onMounted={handleMounted}
                        onInteraction={handleOnInteraction}
                        formParams={{
                            allowSubmit: false,
                            isCardHolderVisible: true,
                        }}
                        onCard={handleValidatedCard}
                    />
                )
            ) : (
                <SuccessArea
                    showcaseItem={showcaseItem}
                    orderId={orderId}
                    subscribeType={subscribeType}
                    price={price}
                />
            )}
            {isMounted && !isSuccess && (
                <div className='mb-4 flex w-full flex-col items-center gap-1'>
                    <button
                        type='button'
                        className={twMerge(
                            'z-30 mx-auto h-fit rounded-lg border py-2 font-semibold sm:max-w-[350px]',
                            subscribeType
                                ? 'bg-gold-gradient text-black'
                                : 'bg-like text-white',
                            isFormValid ? 'cursor-pointer' : 'opacity-50'
                        )}
                        style={{
                            width: width,
                            fontSize: `${scaleFont(16)}px`,
                        }}
                        onClick={handleButtonClick}
                        disabled={!isFormValid}
                    >
                        {buttonText}
                    </button>
                    <div className='flex gap-1 text-stroke'>
                        <Lock />
                        <span
                            style={{
                                fontSize: `${scaleFont(12)}px`,
                                lineHeight: `${scaleFont(16)}px`,
                            }}
                        >
                            Secure checkout
                        </span>
                    </div>
                </div>
            )}
            <PaymentRestrictedModal
                reason={restrictedReason ?? 'unknown'}
                allowedCardBrands={ALLOWED_CARD_BRANDS}
                isOpen={openedRestrictedCardBrandModal}
                onClose={hideRestrictedCardBrandModal}
            />
        </div>
    );
};

export default PaymentMethod;
