import ArrowUp from '@/assets/icons/arrow-up.svg?react';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import React, { useState, useRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

interface SelectInputProps {
    options: number[];
    value: number;
    setSelect?: (value: number) => void;
    units?: string;
    edit?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
    options,
    value,
    setSelect,
    units,
    edit = false,
}) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const [selectedOption, setSelectedOption] = useState<number>(value);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const index = options.indexOf(value);
    const [selectedPoss, setSelectedPoss] = useState<number>(100);
    useEffect(() => {
        setSelectedOption(value);
    }, [value]);
    useEffect(() => {
        setSelectedPoss((index - 3) * 49);
    }, [index]);

    useEffect(() => {
        if (isOpen && divRef.current) {
            divRef.current.scrollTo(0, selectedPoss);
        }
    }, [selectedPoss, isOpen]);

    const handleSelect = (option: number, i: number) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (setSelect) {
            setSelect(option);
        }
        setSelectedPoss((i - 2) * 49);
    };

    return (
        <div className='relative'>
            <div
                id='selectOption'
                className={twMerge(
                    'relative z-10 flex w-[70vw] cursor-pointer justify-between rounded-xl border bg-white px-4 py-2 text-black lg:w-[630px]',
                    isOpen && 'border-active outline outline-1 outline-active',
                    edit && 'sm:w-[430px] md:w-[430px] lg:w-[430px]'
                )}
                onClick={() => setIsOpen((prev) => !prev)}
                aria-expanded={isOpen}
            >
                <span className='ml-3 w-full select-none text-center'>
                    {selectedOption} {units && ` ${units}`}
                </span>
                <div className='flex items-center'>
                    {isOpen ? (
                        <ArrowUp className='text-active' />
                    ) : (
                        <ArrowUp className='rotate-180 transform text-nav-dark-gray' />
                    )}
                </div>
            </div>
            {isOpen && (
                <div
                    className={twMerge(
                        '-mt-3 absolute max-h-[300px] w-[70vw] overflow-y-auto rounded-b-xl border border-gray-200 bg-white pt-3 text-black shadow-lg lg:w-[630px]',
                        edit && 'sm:w-[430px] md:w-[430px] lg:w-[430px]'
                    )}
                    ref={divRef}
                >
                    {options.map((option: number, i: number) => (
                        <div
                            key={`option${option} `}
                            className='flex justify-center border-gray border-b px-2 py-1'
                        >
                            <span
                                className={twMerge(
                                    'flex w-full cursor-pointer select-none flex-col items-center rounded-xl bg-white py-2 hover:bg-sky-100',
                                    option === selectedOption && 'bg-sky-200'
                                )}
                                onClick={() => handleSelect(option, i)}
                                style={{
                                    fontSize: `${scaleFont(14)}px`,
                                }}
                            >
                                {option} {units && ` ${units}`}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SelectInput;
