import CheckboxInput from '@/components/common/CheckBoxInput';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import { MatchGender } from '@/domain';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import React, { useCallback, useState } from 'react';
import StepHeader from './StepHeader';
import { UserInfoStepProps } from './userInfoConfig';

export interface Choice {
    value: MatchGender;
    label: string;
}

export const lookingForItems: Choice[] = [
    {
        value: 'M',
        label: 'Man',
    },
    {
        value: 'F',
        label: 'Woman',
    },
    {
        value: 'U',
        label: 'Both',
    },
];

const LookingForStep: React.FC<UserInfoStepProps> = ({ onNext, isLite }) => {
    const [lookingFor, setLookingFor] = useState(
        () => localStorage.getItem('match_gender') || ''
    );

    const onChoiceChange = useCallback(
        (newValue: string) => {
            setLookingFor(newValue);
            localStorage.setItem('match_gender', newValue);
            onNext();
        },
        [onNext]
    );

    return (
        <UserDataFlowLayout disableNextButton={!lookingFor} withBarProgress>
            <div className='flex flex-col'>
                <StepHeader
                    title='Who are you looking for?'
                    description="You'll only see ones that you choose"
                    barProgress={isLite ? 30 : 60}
                />
                <div
                    className=' flex flex-col px-4'
                    style={{
                        marginTop: `${scaleSize(30)}px`,
                    }}
                >
                    {lookingForItems.map((item) => (
                        <div
                            key={item.value}
                            className='flex w-[100%] cursor-pointer flex-row-reverse justify-between rounded-xl text-black'
                        >
                            <CheckboxInput
                                checked={lookingFor === item.value}
                                onChange={() => onChoiceChange(item.value)}
                                label={item.label}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </UserDataFlowLayout>
    );
};

export default LookingForStep;
